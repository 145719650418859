<!--
 * @Author: Hou Jiechao
 * @Date: 2022-01-28 10:02:18
 * @LastEditors: Hou Jiechao
 * @LastEditTime: 2022-01-28 10:03:35
 * @FilePath: \工作\storehouse\src\pages\3d\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "3d",
  components: {
  },
};
</script>